import { createSlice } from "@reduxjs/toolkit";

export const callTypes = {
	list: "list",
	action: "action"
};

const initState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: null,
	lastError: null,
	item: {
		contri_task: [],
		project: {},
		task: {},
		contributor: {},
		answer: [],
		correct: [],
		correct_answer: [],
		remarks: [],
		questions: [],
		submission: {},
		createdAt: null,
	}
};

export const slice = createSlice({
	name: "submission",
	initialState: initState,
	reducers: {
		startCall: (state, action) => {
			state.error = null;
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true;
			} else {
				state.actionsLoading = true;
			}
		},
		fetched: (state, action) => {
			const { totalCount, entities } = action.payload;
			state.listLoading = false;
			state.error = null;
			state.entities = entities;
			state.totalCount = totalCount;
		},
		fetchedItem: (state, action) => {
			state.actionsLoading = false;
			if (action.payload.item) {
				state.item = action.payload.item;
			} else {
				state.item = initState.item;
			}
			state.error = null;
		},
		exportItem: (state, action) => {
			state.actionsLoading = false;
			state.error = null;
		},
		deleteItem: (state, action) => {
			state.actionsLoading = false;
			state.error = null;
		},
		created: (state, action) => {
			state.actionsLoading = false;
			state.error = null;
		},
		updated: (state, action) => {
			state.actionsLoading = false;
			state.error = null;
		}
	}
})