import React, {useMemo, useEffect, useState} from "react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";

import { 
	AdminDashboardWidget,
	PMDashboardWidget,
	ContributorDashboardWidget

} from "./widgets"
import { getDashboard } from "./service"

export function DashboardPage() {

	const { auth } = useSelector(
		(state) => ({ auth: state.auth }),
		shallowEqual
	);

	const { user } = auth;

	const [data, setData] = useState(null)

	useEffect(() => {
		getDashboard().then(response => {
			setData(response)
		})

	}, []);

	return (
		<>
			{ data != null && 
				<>
					{ user.type == 0 &&
						<AdminDashboardWidget className="gutter-b card-stretch" data={data} />
					}
					{ user.type == 1 &&
						<PMDashboardWidget className="gutter-b card-stretch" data={data} />
					}
					{ user.type == 2 &&
						<ContributorDashboardWidget className="gutter-b card-stretch" data={data} />
					}
				</>
			}
		</>
		
	);
}
