/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo, useEffect} from "react";

import objectPath from "object-path";
import ApexCharts from "apexcharts";
import SVG from "react-inlinesvg";
import {useHtmlClassService} from "../../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import moment from 'moment';
import { useIntl } from "react-intl";

export function AdminDashboardWidget({ className, data }) {
	const intl = useIntl();

	return (
		<>
			<div className="row">
				<div className="col-4">
					<div className={`card card-custom ${className}`}>
						<div className="card-body d-flex flex-column p-0">
							<div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
								<span className="symbol symbol-circle symbol-50 symbol-light-primary mr-2">
									<span className="symbol-label">
										<span className="svg-icon svg-icon-xl svg-icon-success">
											<SVG
												src={toAbsoluteUrl(
													"/media/svg/icons/Communication/Urgent-mail.svg"
												)}
											></SVG>
										</span>
									</span>
								</span>
								<div className="d-flex flex-column text-right">
									<span className="text-dark-75 font-weight-bolder font-size-h3">
										{ data.active }
									</span>
									<span className="text-muted font-weight-bold mt-2">{ intl.formatMessage({ id: "Active Projects" }) }</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-4">
					<div className={`card card-custom ${className}`}>
						<div className="card-body d-flex flex-column p-0">
							<div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
								<span className="symbol symbol-circle symbol-50 symbol-light-primary mr-2">
									<span className="symbol-label">
										<span className="svg-icon svg-icon-xl svg-icon-secondary">
											<SVG
												src={toAbsoluteUrl(
													"/media/svg/icons/Communication/Group.svg"
												)}
											></SVG>
										</span>
									</span>
								</span>
								<div className="d-flex flex-column text-right">
									<span className="text-dark-75 font-weight-bolder font-size-h3">
										{ data.late_project }
									</span>
									<span className="text-muted font-weight-bold mt-2">{ intl.formatMessage({ id: "Late Projects" }) }</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-4">
					<div className={`card card-custom ${className}`}>
						<div className="card-body d-flex flex-column p-0">
							<div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
								<span className="symbol symbol-circle symbol-50 symbol-light-primary mr-2">
									<span className="symbol-label">
										<span className="svg-icon svg-icon-xl svg-icon-secondary">
											<SVG
												src={toAbsoluteUrl(
													"/media/svg/icons/Communication/Group.svg"
												)}
											></SVG>
										</span>
									</span>
								</span>
								<div className="d-flex flex-column text-right">
									<span className="text-dark-75 font-weight-bolder font-size-h3">
										{ data.late_task }
									</span>
									<span className="text-muted font-weight-bold mt-2">{ intl.formatMessage({ id: "Late Tasks" }) }</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-6">
					<div className={`card card-custom ${className}`}>
						<div className="card-header border-0 pt-5">
							<h3 className="card-title align-items-start flex-column">
								<span className="card-label font-weight-bolder text-dark">
									{ intl.formatMessage({ id: "Contributor Task Performance" }) }
								</span>
							</h3>
						</div>
						<div className="card-body pt-2 pb-0">
							<div className="table-responsive">
								<table className="table table-borderless table-vertical-center">
									<thead>
										<tr>
											<th className="p-0"></th>
											<th className="p-0">{ intl.formatMessage({ id: "Active Tasks" }) }</th>
											<th className="p-0">{ intl.formatMessage({ id: "Late Tasks" }) }</th>
											<th className="p-0">{ intl.formatMessage({ id: "Submitted Tasks" }) }</th>
										</tr>
									</thead>
									<tbody>
										{ data.active_task && data.active_task.length > 0 && data.active_task.map((item, index) => {
											return (
												<>
												<tr>
													<td className="pl-0">
														<span
															className="text-dark font-weight-bolder mb-1 font-size-lg"
														>
															{item.user.name}
														</span>
														<span className="text-muted font-weight-bold d-block">
															{item.user.username}
														</span>
													</td>
													<td className="pl-0">
														<span>
															{item.active}
														</span>
													</td>
													<td className="pl-0">
														<span>
															{item.late}
														</span>
													</td>
													<td className="pl-0">
														<span>
															{item.submitted}
														</span>
													</td>
												</tr>
												</>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div className="col-6">
					<div className={`card card-custom ${className}`}>
						<div className="card-header border-0 pt-5">
							<h3 className="card-title align-items-start flex-column">
								<span className="card-label font-weight-bolder text-dark">
									{ intl.formatMessage({ id: "PM Projects Status" }) }
								</span>
							</h3>
						</div>
						<div className="card-body pt-2 pb-0">
							<div className="table-responsive">
								<table className="table table-borderless table-vertical-center">
									<thead>
										<tr>
											<th className="p-0"></th>
											<th className="p-0">{ intl.formatMessage({ id: "Active Projects" }) }</th>
											<th className="p-0">{ intl.formatMessage({ id: "Draft Projects" }) }</th>
											<th className="p-0">{ intl.formatMessage({ id: "Completed Projects" }) }</th>
											<th className="p-0">{ intl.formatMessage({ id: "Overdue Projects" }) }</th>
										</tr>
									</thead>
									<tbody>
										{ data.active_project && data.active_project.length > 0 && data.active_project.map((item, index) => {
											return (
												<>
												<tr>
													<td className="pl-0">
														<span
															className="text-dark font-weight-bolder mb-1 font-size-lg"
														>
															{item.user.name}
														</span>
														<span className="text-muted font-weight-bold d-block">
															{item.user.username}
														</span>
													</td>
													<td className="pl-0">
														<span>
															{item.active}
														</span>
													</td>
													<td className="pl-0">
														<span>
															{item.is_draft}
														</span>
													</td>
													<td className="pl-0">
														<span>
															{item.is_completed}
														</span>
													</td>
													<td className="pl-0">
														<span>
															{item.late}
														</span>
													</td>
												</tr>
												</>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div className="col-6">
					<div className={`card card-custom ${className}`}>
						<div className="card-header border-0 pt-5">
							<h3 className="card-title align-items-start flex-column">
								<span className="card-label font-weight-bolder text-dark">
									{ intl.formatMessage({ id: "Contributor Status" }) }
								</span>
							</h3>
						</div>
						<div className="card-body pt-2 pb-0">
							<div className="table-responsive">
								<table className="table table-borderless table-vertical-center">
									<thead>
										<tr>
											<th className="p-0"></th>
											<th className="p-0">{ intl.formatMessage({ id: "Status" }) }</th>
										</tr>
									</thead>
									<tbody>
										{ data.idle_user && data.idle_user.length > 0 && data.idle_user.map((item, index) => {
											return (
												<>
												<tr>
													<td className="pl-0">
														<span
															className="text-dark font-weight-bolder mb-1 font-size-lg"
														>
															{item.name}
														</span>
														<span className="text-muted font-weight-bold d-block">
															{item.username}
														</span>
													</td>
													<td className="pl-0">
														<span>
															{ item.count == 0 && intl.formatMessage({ id: "Idle" }) }
															{ (item.count > 0) && 
																<>
																	{ intl.formatMessage({ id: "Assigned" }) } { item.count } { intl.formatMessage({ id: "Tasks" }) }
																</>
															}
														</span>
													</td>
												</tr>
												</>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div className="col-6">
					<div className={`card card-custom ${className}`}>
						<div className="card-header border-0 pt-5">
							<h3 className="card-title align-items-start flex-column">
								<span className="card-label font-weight-bolder text-dark">
									{ intl.formatMessage({ id: "Contributor Task" }) }
								</span>
							</h3>
						</div>
						<div className="card-body pt-2 pb-0">
							<div className="table-responsive">
								<table className="table table-borderless table-vertical-center">
									<thead>
										<tr>
											<th className="p-0"></th>
											<th className="p-0">{ intl.formatMessage({ id: "Project" }) }</th>
											<th className="p-0">{ intl.formatMessage({ id: "Task" }) }</th>
										</tr>
									</thead>
									<tbody>
										{ data.assign_user && data.assign_user.length > 0 && data.assign_user.map((item, index) => {
											return (
												<>
												<tr>
													<td className="pl-0">
														<span
															className="text-dark font-weight-bolder mb-1 font-size-lg"
														>
															{item.user.name}
														</span>
														<span className="text-muted font-weight-bold d-block">
															{item.user.username}
														</span>
													</td>
													<td className="pl-0">
														<span>
															{item.project.name}
														</span>
													</td>
													<td className="pl-0">
														<span>
															{item.task.name}
														</span>
													</td>
												</tr>
												</>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div className="col-12">
					<div className={`card card-custom ${className}`}>
						<div className="card-header border-0 pt-5">
							<h3 className="card-title align-items-start flex-column">
								<span className="card-label font-weight-bolder text-dark">
									{ intl.formatMessage({ id: "Submission" }) }
								</span>
								<span className="text-muted mt-3 font-weight-bold font-size-sm">
									{ intl.formatMessage({ id: "Latest Submission" }) }
								</span>
							</h3>
						</div>
						<div className="card-body pt-2 pb-0">
							<div className="table-responsive">
								<table className="table table-borderless table-vertical-center">
									<thead>
										<tr>
											<th className="p-0"></th>
											<th className="p-0">{ intl.formatMessage({ id: "Project" }) }</th>
											<th className="p-0">{ intl.formatMessage({ id: "Task" }) }</th>
											<th className="p-0">{ intl.formatMessage({ id: "Date" }) }</th>
										</tr>
									</thead>
									<tbody>
										{ data.submission_log && data.submission_log.length > 0 && data.submission_log.map((item, index) => {
											return (
												<>
												<tr>
													<td className="pl-0">
														<span
															className="text-dark font-weight-bolder mb-1 font-size-lg"
														>
															{item.user.name}
														</span>
														<span className="text-muted font-weight-bold d-block">
															{item.user.username}
														</span>
													</td>
													<td className="pl-0">
														<span>
															{ item.project && item.project.name }
														</span>
													</td>
													<td className="pl-0">
														<span>
															{ item.task && item.task.name }
														</span>
													</td>
													<td className="pl-0">
														<span>
															{ moment(item.updatedAt).utcOffset('+0800').format('LLL') }
														</span>
													</td>
												</tr>
												</>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
