import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { slice as user } from "../app/pages/User/reducer";
import { slice as project } from "../app/pages/Project/reducer";
import { slice as category } from "../app/pages/Category/reducer";
import { slice as task } from "../app/pages/Task/reducer";
import { slice as userTask } from "../app/pages/UserTask/reducer";
import { slice as submission } from "../app/pages/Submission/reducer";

export const rootReducer = combineReducers({
	auth: auth.reducer,
	user: user.reducer,
	project: project.reducer,
	category: category.reducer,
	task: task.reducer,
	userTask: userTask.reducer,
	submission: submission.reducer
});

export function* rootSaga() {
	yield all([auth.saga()]);
}
