/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo, useEffect} from "react";

import objectPath from "object-path";
import ApexCharts from "apexcharts";
import SVG from "react-inlinesvg";
import {useHtmlClassService} from "../../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import moment from 'moment';
import { useIntl } from "react-intl";
import Alert from 'react-bootstrap/Alert';

export function ContributorDashboardWidget({ className, data }) {
	
	const intl = useIntl();

	return (
		<>
			{ data.lifetime_stat.total == 0 &&
				<div className="row">
					<div className="col-12">
						<Alert variant="dark">
							{ intl.formatMessage({ id: "Do not have tasks" }) } <a href="mailto:craftinquiries@craft.hk">craftinquiries@craft.hk</a>
						</Alert>
					</div>
				</div>
			}
			<div className="row">
				<div className="col-4">
					<div className={`card card-custom ${className}`}>
						<div className="card-body d-flex flex-column p-0">
							<div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
								<span className="symbol symbol-circle symbol-50 symbol-light-primary mr-2">
									<span className="symbol-label">
										<span className="svg-icon svg-icon-xl svg-icon-success">
											<SVG
												src={toAbsoluteUrl(
													"/media/svg/icons/Communication/Urgent-mail.svg"
												)}
											></SVG>
										</span>
									</span>
								</span>
								<div className="d-flex flex-column text-right">
									<span className="text-dark-75 font-weight-bolder font-size-h3">
										{ data.lifetime_stat.completed } / { data.lifetime_stat.total }
									</span>
									<span className="text-muted font-weight-bold mt-2">{ intl.formatMessage({ id: "Completed Tasks" }) }</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-4">
					<div className={`card card-custom ${className}`}>
						<div className="card-body d-flex flex-column p-0">
							<div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
								<span className="symbol symbol-circle symbol-50 symbol-light-primary mr-2">
									<span className="symbol-label">
										<span className="svg-icon svg-icon-xl svg-icon-secondary">
											<SVG
												src={toAbsoluteUrl(
													"/media/svg/icons/Communication/Group.svg"
												)}
											></SVG>
										</span>
									</span>
								</span>
								<div className="d-flex flex-column text-right">
									<span className="text-dark-75 font-weight-bolder font-size-h3">
										{ data.lifetime_stat.active } / { data.lifetime_stat.total }
									</span>
									<span className="text-muted font-weight-bold mt-2">{ intl.formatMessage({ id: "In Progress Tasks" }) }</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-4">
					<div className={`card card-custom ${className}`}>
						<div className="card-body d-flex flex-column p-0">
							<div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
								<span className="symbol symbol-circle symbol-50 symbol-light-primary mr-2">
									<span className="symbol-label">
										<span className="svg-icon svg-icon-xl svg-icon-secondary">
											<SVG
												src={toAbsoluteUrl(
													"/media/svg/icons/Communication/Group.svg"
												)}
											></SVG>
										</span>
									</span>
								</span>
								<div className="d-flex flex-column text-right">
									<span className="text-dark-75 font-weight-bolder font-size-h3">
										{ data.lifetime_stat.late } / { data.lifetime_stat.total }
									</span>
									<span className="text-muted font-weight-bold mt-2">{ intl.formatMessage({ id: "Late Tasks" }) }</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className={`card card-custom ${className}`}>
						<div className="card-header border-0 pt-5">
							<h3 className="card-title align-items-start flex-column">
								<span className="card-label font-weight-bolder text-dark">
									{ intl.formatMessage({ id: "Task Performance" }) }
								</span>
								<span className="text-muted mt-3 font-weight-bold font-size-sm">
									
								</span>
							</h3>
						</div>
						<div className="card-body pt-2 pb-0">
							<div className="table-responsive">
								<table className="table table-borderless table-vertical-center">
									<thead>
										<tr>
											<th className="p-0">{ intl.formatMessage({ id: "Project" }) }</th>
											<th className="p-0">{ intl.formatMessage({ id: "Task" }) }</th>
											<th className="p-0">{ intl.formatMessage({ id: "Correct" }) }</th>
											<th className="p-0">{ intl.formatMessage({ id: "Incorrect" }) }</th>
										</tr>
									</thead>
									<tbody>
										{ data.submission_log && data.submission_log.length > 0 && data.submission_log.map((item, index) => {
											return (
												<>
												<tr>
													<td className="pl-0">
														<span>
															{item.project_detail.name}
														</span>
													</td>
													<td className="pl-0">
														<span>
															{item.task_detail.name}
														</span>
													</td>
													<td className="pl-0">
														<span>
															{item.submission.correct_count} / { item.submission.correct_count + item.submission.incorrect_count } ( { (item.submission.correct_count / (item.submission.correct_count + item.submission.incorrect_count)).toFixed(2) * 100 }% )
														</span>
													</td>
													<td className="pl-0">
														<span>
															{item.submission.incorrect_count} / { item.submission.correct_count + item.submission.incorrect_count } ( { (item.submission.incorrect_count / (item.submission.correct_count + item.submission.incorrect_count)).toFixed(2) * 100 }% )
														</span>
													</td>
												</tr>
												</>
											)
										})}
										
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div className="col-12">
					<div className={`card card-custom ${className}`}>
						<div className="card-header border-0 pt-5">
							<h3 className="card-title align-items-start flex-column">
								<span className="card-label font-weight-bolder text-dark">
									{ intl.formatMessage({ id: "Outstanding Task" }) }
								</span>
								<span className="text-muted mt-3 font-weight-bold font-size-sm">
									
								</span>
							</h3>
						</div>
						<div className="card-body pt-2 pb-0">
							<div className="table-responsive">
								<table className="table table-borderless table-vertical-center">
									<thead>
										<tr>
											<th className="p-0">{ intl.formatMessage({ id: "Project" }) }</th>
											<th className="p-0">{ intl.formatMessage({ id: "Task" }) }</th>
											<th className="p-0">{ intl.formatMessage({ id: "Status" }) }</th>
											<th className="p-0">{ intl.formatMessage({ id: "Date" }) }</th>
										</tr>
									</thead>
									<tbody>
										{ data.task && data.task.length > 0 && data.task.map((item, index) => {
											return (
												<>
												<tr>
													<td className="pl-0">
														<span>
															{item.project_detail.name}
														</span>
													</td>
													<td className="pl-0">
														<span>
															<a href={ "/task/" + item.task }>{item.task_detail.name}</a>
														</span>
													</td>
													<td className="pl-0">
														<span>
															{ item.submission.is_completed && item.submission.status == 1 && intl.formatMessage({ id: "Under Review" }) }
															{ item.submission.is_completed && item.submission.status == 2 && intl.formatMessage({ id: "Approved" }) }
															{ !item.submission.is_completed && 
																<>
																	{ intl.formatMessage({ id: "Incomplete" }) + " - " + item.answers_count } / { item.questions_count }
																</>
															}
														</span>
													</td>
													<td className="pl-0">
														<span>
															{ moment(item.submission.updatedAt).utcOffset('+0800').format('LLL') }
														</span>
													</td>
												</tr>
												</>
											)
										})}
										
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
