import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { useSelector } from "react-redux";

import { DashboardPage } from "./pages/Dashboard/Page";

const User = lazy(() => import("./pages/User/Page"));
const Project = lazy(() => import("./pages/Project/Page"));
const Category = lazy(() => import("./pages/Category/Page"));
const Task = lazy(() => import("./pages/Task/Page"));
const UserTask = lazy(() => import("./pages/UserTask/Page"));
const Submission = lazy(() => import("./pages/Submission/Page"));

export default function BasePage() {
	const { user } = useSelector((state) => state.auth);

	// useEffect(() => {
	//	 console.log('Base page');
	// }, []) // [] - is required if you need only one call
	// https://reactjs.org/docs/hooks-reference.html#useeffect

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					/* Redirect from root URL to /dashboard. */
					<Redirect exact from="/" to="/dashboard" />
				}
				<ContentRoute path="/dashboard" component={DashboardPage} />
				{	user.type != 2 &&
					<>
						<Route path="/user" component={User} />
						<Route path="/project" component={Project} />
						<Route path="/category" component={Category} />
						<Route path="/task" component={Task} />
						<Route path="/submission" component={Submission} />
					</>
				}
				{	user.type == 2 &&
					<Route path="/task" component={UserTask} />
				}
				<Redirect to="error/error-v1" />
			</Switch>
		</Suspense>
	);
}
