import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/cms/v1/auth/login`;
export const REGISTER_URL = `${process.env.REACT_APP_API_URL}/cms/v1/auth/register`;
export const FETCH_USER_URL = `${process.env.REACT_APP_API_URL}/cms/v1/auth/fetchUser`;
export const GET_USER_URL = `${process.env.REACT_APP_API_URL}/cms/v1/auth/getUser`;
export const REQUEST_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/cms/v1/auth/requestPassword`;

export function login(username, password) {
	return axios.post(LOGIN_URL, { username, password });
}

export function register(token, password) {
	return axios.post(REGISTER_URL, { token, password });
}

export function fetchUser(token) {
	return axios.post(FETCH_USER_URL, { token });
}

export function requestPassword(email) {
	return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
	// Authorization head should be fulfilled in interceptor.
	return axios.get(GET_USER_URL);
}
