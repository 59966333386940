/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useIntl } from "react-intl";

export function AsideMenuList({ layoutProps }) {
	const { user } = useSelector((state) => state.auth);
	const intl = useIntl();
	const location = useLocation();
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu &&
					"menu-item-active"} menu-item-open menu-item-not-hightlighted`
			: "";
	};

	if (user.type == 2) {
		return (
			<>
				{/* begin::Menu Nav */}
				<ul className={`menu-nav ${layoutProps.ulClasses}`}>
					{/*begin::1 Level*/}
					<li
						className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
						aria-haspopup="true"
					>
						<NavLink className="menu-link" to="/dashboard">
							<span className="svg-icon menu-icon">
								<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
							</span>
							<span className="menu-text">{ intl.formatMessage({ id: "Dashboard" }) }</span>
						</NavLink>
					</li>
					
					<li
						className={`menu-item ${getMenuItemActive("/task", false)}`}
						aria-haspopup="true"
					>
						<NavLink className="menu-link" to="/task">
							<span className="svg-icon menu-icon">
								<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
							</span>
							<span className="menu-text">{ intl.formatMessage({ id: "Task" }) }</span>
						</NavLink>
					</li>

				</ul>
			</>
		)

	} else if (user.type == 1) {

		return (
			<>
				{/* begin::Menu Nav */}
				<ul className={`menu-nav ${layoutProps.ulClasses}`}>
					{/*begin::1 Level*/}
					
					<li
						className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
						aria-haspopup="true"
					>
						<NavLink className="menu-link" to="/dashboard">
							<span className="svg-icon menu-icon">
								<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
							</span>
							<span className="menu-text">{ intl.formatMessage({ id: "Dashboard" }) }</span>
						</NavLink>
					</li>
					
					{/*end::1 Level*/}

					{/* begin::section */}
					<li className="menu-section ">
						<h4 className="menu-text">{ intl.formatMessage({ id: "Content" }) }</h4>
						<i className="menu-icon flaticon-more-v2"></i>
					</li>
					{/* end:: section */}
					<li 
						className={`menu-item menu-item-submenu ${ getMenuItemActive("/project", true )} ${ getMenuItemActive("/task", true )} ${ getMenuItemActive("/category", true )} `}
						aria-haspopup="true"
						data-menu-toggle="hover"
					>
						<NavLink className="menu-link menu-toggle" to="/project">
							<span className="svg-icon menu-icon">
								<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
							</span>
							<span className="menu-text">{ intl.formatMessage({ id: "Project" }) }</span>
							<i className="menu-arrow" />
						</NavLink>
						<div className="menu-submenu ">
							<i className="menu-arrow" />
							<ul className="menu-subnav">
								<li className={`menu-item ${getMenuItemActive("/project/list")}`} aria-haspopup="true">
									<NavLink className="menu-link" to="/project/list">
										<i className="menu-bullet menu-bullet-dot">
											<span />
										</i>
										<span className="menu-text">{ intl.formatMessage({ id: "Project List" }) }</span>
									</NavLink>
								</li>
								<li className={`menu-item ${getMenuItemActive("/project/add")}`} aria-haspopup="true">
									<NavLink className="menu-link" to="/project/add">
										<i className="menu-bullet menu-bullet-dot">
											<span />
										</i>
										<span className="menu-text">{ intl.formatMessage({ id: "Add Project" }) }</span>
									</NavLink>
								</li>
								<li className={`menu-item ${getMenuItemActive("/task/list")}`} aria-haspopup="true">
									<NavLink className="menu-link" to="/task/list">
										<i className="menu-bullet menu-bullet-dot">
											<span />
										</i>
										<span className="menu-text">{ intl.formatMessage({ id: "Task List" }) }</span>
									</NavLink>
								</li>
								<li className={`menu-item ${getMenuItemActive("/category/list")}`} aria-haspopup="true">
									<NavLink className="menu-link" to="/category/list">
										<i className="menu-bullet menu-bullet-dot">
											<span />
										</i>
										<span className="menu-text">{ intl.formatMessage({ id: "Category" }) }</span>
									</NavLink>
								</li>
							</ul>
						</div>
					</li>

					{/* begin::section */}
					<li className="menu-section ">
						<h4 className="menu-text">{ intl.formatMessage({ id: "Report" }) }</h4>
						<i className="menu-icon flaticon-more-v2"></i>
					</li>
					{/* end:: section */}
					<li 
						className={`menu-item menu-item-submenu ${ getMenuItemActive("/submission", true )}`}
						aria-haspopup="true"
						data-menu-toggle="hover"
					>
						<NavLink className="menu-link menu-toggle" to="/submission">
							<span className="svg-icon menu-icon">
								<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
							</span>
							<span className="menu-text">{ intl.formatMessage({ id: "Submission" })}</span>
							<i className="menu-arrow" />
						</NavLink>
					</li>
				</ul>

				{/* end::Menu Nav */}
			</>
		);

	} else {

		return (
			<>
				{/* begin::Menu Nav */}
				<ul className={`menu-nav ${layoutProps.ulClasses}`}>
					{/*begin::1 Level*/}
					
					<li
						className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
						aria-haspopup="true"
					>
						<NavLink className="menu-link" to="/dashboard">
							<span className="svg-icon menu-icon">
								<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
							</span>
							<span className="menu-text">{ intl.formatMessage({ id: "Dashboard" }) }</span>
						</NavLink>
					</li>
					
					{/*end::1 Level*/}

					{/* begin::section */}
					<li className="menu-section ">
						<h4 className="menu-text">{ intl.formatMessage({ id: "Content" }) }</h4>
						<i className="menu-icon flaticon-more-v2"></i>
					</li>
					{/* end:: section */}
					<li 
						className={`menu-item menu-item-submenu ${ getMenuItemActive("/project", true )} ${ getMenuItemActive("/task", true )} ${ getMenuItemActive("/category", true )} `}
						aria-haspopup="true"
						data-menu-toggle="hover"
					>
						<NavLink className="menu-link menu-toggle" to="/project">
							<span className="svg-icon menu-icon">
								<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
							</span>
							<span className="menu-text">{ intl.formatMessage({ id: "Project" }) }</span>
							<i className="menu-arrow" />
						</NavLink>
						<div className="menu-submenu ">
							<i className="menu-arrow" />
							<ul className="menu-subnav">
								<li className={`menu-item ${getMenuItemActive("/project/list")}`} aria-haspopup="true">
									<NavLink className="menu-link" to="/project/list">
										<i className="menu-bullet menu-bullet-dot">
											<span />
										</i>
										<span className="menu-text">{ intl.formatMessage({ id: "Project List" }) }</span>
									</NavLink>
								</li>
								<li className={`menu-item ${getMenuItemActive("/project/add")}`} aria-haspopup="true">
									<NavLink className="menu-link" to="/project/add">
										<i className="menu-bullet menu-bullet-dot">
											<span />
										</i>
										<span className="menu-text">{ intl.formatMessage({ id: "Add Project" }) }</span>
									</NavLink>
								</li>
								<li className={`menu-item ${getMenuItemActive("/task/list")}`} aria-haspopup="true">
									<NavLink className="menu-link" to="/task/list">
										<i className="menu-bullet menu-bullet-dot">
											<span />
										</i>
										<span className="menu-text">{ intl.formatMessage({ id: "Task List" }) }</span>
									</NavLink>
								</li>
								<li className={`menu-item ${getMenuItemActive("/category/list")}`} aria-haspopup="true">
									<NavLink className="menu-link" to="/category/list">
										<i className="menu-bullet menu-bullet-dot">
											<span />
										</i>
										<span className="menu-text">{ intl.formatMessage({ id: "Category" }) }</span>
									</NavLink>
								</li>
							</ul>
						</div>
					</li>

					{/* begin::section */}
					<li className="menu-section ">
						<h4 className="menu-text">{ intl.formatMessage({ id: "Report" }) }</h4>
						<i className="menu-icon flaticon-more-v2"></i>
					</li>
					{/* end:: section */}
					<li 
						className={`menu-item menu-item-submenu ${ getMenuItemActive("/submission", true )}`}
						aria-haspopup="true"
						data-menu-toggle="hover"
					>
						<NavLink className="menu-link menu-toggle" to="/submission">
							<span className="svg-icon menu-icon">
								<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
							</span>
							<span className="menu-text">{ intl.formatMessage({ id: "Submission" })}</span>
							<i className="menu-arrow" />
						</NavLink>
					</li>

					{/* begin::section */}
					<li className="menu-section ">
						<h4 className="menu-text">{ intl.formatMessage({ id: "User" }) }</h4>
						<i className="menu-icon flaticon-more-v2"></i>
					</li>
					{/* end:: section */}
					<li 
						className={`menu-item menu-item-submenu ${ getMenuItemActive("/user", true )}`}
						aria-haspopup="true"
						data-menu-toggle="hover"
					>
						<NavLink className="menu-link menu-toggle" to="/user">
							<span className="svg-icon menu-icon">
								<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
							</span>
							<span className="menu-text">{ intl.formatMessage({ id: "User" }) }</span>
							<i className="menu-arrow" />
						</NavLink>
						<div className="menu-submenu ">
							<i className="menu-arrow" />
							<ul className="menu-subnav">
								<li className={`menu-item ${getMenuItemActive("/user/list")}`} aria-haspopup="true">
									<NavLink className="menu-link" to="/user/list">
										<i className="menu-bullet menu-bullet-dot">
											<span />
										</i>
										<span className="menu-text">{ intl.formatMessage({ id: "User List" }) }</span>
									</NavLink>
								</li>
								<li className={`menu-item ${getMenuItemActive("/user/add")}`} aria-haspopup="true">
									<NavLink className="menu-link" to="/user/add">
										<i className="menu-bullet menu-bullet-dot">
											<span />
										</i>
										<span className="menu-text">{ intl.formatMessage({ id: "Add User" }) }</span>
									</NavLink>
								</li>
							</ul>
						</div>
					</li>
				</ul>

				{/* end::Menu Nav */}
			</>
		);
	
	}
}
