/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { useIntl } from "react-intl";

export function AuthPage() {
	const intl = useIntl();

	return (
		<>
			<div className="d-flex flex-column flex-root">
				{/*begin::Login*/}
				<div
					className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
					id="kt_login"
				>
					{/*begin::Aside*/}
					<div
						className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
						style={{
							backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-5.jpg")})`,
						}}
					>
						{/*begin: Aside Container*/}
						<div className="d-flex flex-row-fluid flex-column justify-content-between">
							{/* start:: Aside header */}
							{/*<Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
								<img
									alt="Logo"
									className="max-h-70px"
									src={toAbsoluteUrl("/img/logo.png")}
								/>
							</Link>*/}
							{/* end:: Aside header */}

							{/* start:: Aside content */}
							<div className="flex-column-fluid d-flex flex-column justify-content-center">
								<h3 className="font-size-h1 mb-5 text-white">
									匠心传播
								</h3>
								<h3 className="font-size-h1 mb-5 text-white">
									Craft Associates
								</h3>
								<p className="text-white">
									{ intl.formatMessage({ id: "Login Message" }) }
								</p>
								<p className="text-white">
									Data Labeler by Craft Associates.  A self-service platform for content coding, sentiment analysis and quality check in preparation for big data analysis and modeling.
								</p>
							</div>
							{/* end:: Aside content */}

							{/* start:: Aside footer for desktop */}
							<div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
								<div className="opacity-70 font-weight-bold	text-white">
									&copy; 2022 Craft Associates Ltd.
								</div>
							</div>
							{/* end:: Aside footer for desktop */}
						</div>
						{/*end: Aside Container*/}
					</div>
					{/*begin::Aside*/}

					{/*begin::Content*/}
					<div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
						{/* begin::Content body */}
						<div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
							<Switch>
								<ContentRoute path="/auth/login" component={Login} />
								<ContentRoute path="/auth/registration/:token" component={Registration} />
								<ContentRoute
									path="/auth/forgot-password"
									component={ForgotPassword}
								/>
								<Redirect from="/auth" exact={true} to="/auth/login" />
								<Redirect to="/auth/login" />
							</Switch>
						</div>
						{/*end::Content body*/}

						{/* begin::Mobile footer */}
						<div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
							<div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
								&copy; 2022 Craft Associates Ltd.
							</div>
						</div>
						{/* end::Mobile footer */}
					</div>
					{/*end::Content*/}
				</div>
				{/*end::Login*/}
			</div>
		</>
	);
}
