import { createSlice } from "@reduxjs/toolkit";

export const callTypes = {
	list: "list",
	action: "action"
};

const initState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: null,
	lastError: null,
	item: {
		project: {
			name: ''
		},
		task_row: [],
		questions: [{
			use_iframe: false,
			name: ''
		}],
		answer: [],
		answered: 0,
		questions: 0
	},
	project: []
};

export const slice = createSlice({
	name: "userTask",
	initialState: initState,
	reducers: {
		startCall: (state, action) => {
			state.error = null;
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true;
			} else {
				state.actionsLoading = true;
			}
		},
		fetched: (state, action) => {
			const { totalCount, entities } = action.payload;
			state.listLoading = false;
			state.error = null;
			state.entities = entities;
			state.totalCount = totalCount;
		},
		fetchedItem: (state, action) => {
			state.actionsLoading = false;
			if (action.payload.item) {
				state.item = action.payload.item;
			} else {
				state.item = initState.item;
			}
			if (state.item.answer) {
				state.item.answer = JSON.parse(state.item.answer)
			} else {
				state.item.answer = new Array(state.item.task_row.length).fill(0).map(() => new Array(state.item.questions.length).fill(0))
			}
			state.project = action.payload.project;
			state.error = null;
		},
		created: (state, action) => {
			state.actionsLoading = false;
			state.error = null;
		},
		updated: (state, action) => {
			state.actionsLoading = false;
			state.error = null;
		}
	}
})